import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from "@angular/router";
import { DefaultLayoutComponent } from '../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  email: any;
  password: any;
   message: string = '';
   isSubmit: boolean = false;

  constructor(
    private userService: UserService,
    public router: Router,
    public DefaultLayoutComponent: DefaultLayoutComponent,
  ) {}
  
  ngOnInit() {
    let token = this.userService.getUserToken();
    if (token) {
      this.router.navigate(['/dashboard'])
    }
  }

  public login(email: string, password: string) {
    if (email && password) {
      this.userService.isSpinnerVisible = true;
      var userData = {
        email: email,
        password: password,
        role: 'admin'
      }
      this.isSubmit = true;
      this.userService.login(userData).subscribe((response) => {
        console.log(response, 'response');
        
        this.userService.isSpinnerVisible = false;
        this.userService.setUserToken(response['id']);
        this.userService.setUserID(response['userId']);
        this.userService.setUserName(response['user'].firstName+' '+response['user'].lastName );
        this.userService.setFirstName(response['user'].firstName);
        this.userService.setLastName(response['user'].lastName);
        this.userService.setEmail(response["user"]["email"]);
        this.userService.setProfilePic(response['user']['profilePic']);
        this.router.navigate(['/dashboard']);
        this.userService.loginFlag = true;  
      }, (error) => {
        this.userService.isSpinnerVisible = false;
        this.isSubmit = false;
      })
    }
  }
}
