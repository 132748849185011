import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

class Response {
  success: boolean;
  status: Number;
  metadata: any;
  data: any;
  message: any;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  userToken: string;
  hideSpinner: boolean;
  isSpinnerVisible = true;
  loginFlag: boolean = false;
  APIURL = environment.APIURL;

  constructor(private http: HttpClient, private toastr: ToastrService) { }



// FUNCTION DEFINITION STARTS
  setUserToken(token: string) {
    window.localStorage.setItem("userToken", token);
  }

  getUserToken() {
    return window.localStorage.getItem("userToken");
  }

  setUserID(userId: string) {
    window.localStorage.setItem("userId", userId);
  }

  getUserID() {
    return window.localStorage.getItem("userId");
  }

  setVerifiedMail(token: string) {
    window.localStorage.setItem("verifiedMail", token);
  }

  getVerifiedMail() {
    return window.localStorage.getItem("verifiedMail");
  }

  setUserName(username: string) {
    window.localStorage.setItem("username", username);
  }

  getUserName() {
    return window.localStorage.getItem("username");
  }

  setFirstName(firstname: string) {
    window.localStorage.setItem("firstname", firstname);
  }

  getFirstName() {
    return window.localStorage.getItem("firstname");
  }

  setLastName(lastname: string) {
    window.localStorage.setItem("lastname", lastname);
  }

  getLastName() {
    return window.localStorage.getItem("lastname");
  }

  setEmail(email: string) {
    window.localStorage.setItem("email", email);
  }

  getEmail() {
    return window.localStorage.getItem("email");
  }

  setProfilePic(profilePic: string) {
    profilePic ? window.localStorage.setItem("profilePic", profilePic) : null;
  }

  getProfilePic() {
    let data = window.localStorage.getItem("profilePic");
    return data;
  }

 
  deleteToken() {
    localStorage.removeItem("userToken");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("userId");
    localStorage.removeItem("profilePic");
  }

  deleteLocalData(key: string) {
    localStorage.removeItem(key);
  }

  getBaseUrl() {
    return location.origin;
  }

  // Toaster for session expiry 
  errorStatus = true;
  errorToaster(message: any) {
    if (this.errorStatus) {
      this.errorStatus = false;
      this.toastr.error(message);
      setTimeout(() => {
        this.errorStatus = true;
      }, 2000);
    }
  }

  /* Manage Admin APIS calling */
  login(data: any) {
    return this.http.post<Response>(this.APIURL + "/api/users/login?include=user", data);
  }

  forgotPassword(data: any) {
    return this.http.post<Response>(this.APIURL + "/api/users/forgot-password", data);
  }

  changePassword(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/change-password?access_token='+this.getUserToken(), data);
  }
  
  signup(data: any) {
    return this.http.post<Response>(this.APIURL + "/api/user/register", data);
  }

  logout() {
    return this.http.get<Response>(this.APIURL + "/api/admin/logout");
  }

  addCompany(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/add-user?access_token='+this.getUserToken(), data)
  }

  checkResetPasswordToken(data: any) {
    return this.http.post<Response>(this.APIURL + "/api/admin/checkResetPasswordToken", data);
  }
  
  resetPassword( data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/alter-password', data);
  }

  getAdminUserById() {
    return this.http.get<Response>(this.APIURL + '/api/users/' + this.getUserID()+'?access_token='+this.getUserToken())
  }

  updateProfile(data: any) {
    return this.http.patch<Response>(this.APIURL + '/api/users/' + this.getUserID()+'?access_token='+this.getUserToken(), data)
  }

  updateCompany(data: any) {
    return this.http.patch<Response>(this.APIURL + '/api/users/update-customer-admin?access_token='+this.getUserToken(), data)
  }

  resendCredential(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/resend-credentials?access_token='+this.getUserToken(), data);
  }

  /* Manage User Pages APIS calling */
  getUserById(userId: string) {
    return this.http.get<Response>(this.APIURL + '/api/users/'+userId+'?filter={"include":"company"}&access_token='+this.getUserToken());
  }

  getUserByIdView(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/get-all-shipping-managers?access_token='+this.getUserToken(), data);
  }

  getFollowersById(userId: string) {
    return this.http.get<Response>(this.APIURL + '/api/user/getFollowersById/' + userId);
  }

  getFriendsByUser(userId: string) {
    return this.http.get<Response>(this.APIURL + `/api/friend/getByUser?userId=${userId}`);
  }
  // getAllUser(queryParams?: any) {
  //   return this.http.get<Response>(this.APIURL + `/api/user/getAll?page=${queryParams.page}&limit=${queryParams.limit}&start=${queryParams.start}&end=${queryParams.end}&search_query=${queryParams.search}&platform=admin`);
  // }
  getAllUser(queryParams?: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/get-company-details'+'?access_token='+this.getUserToken(), queryParams );
  }

  getAllUserCount() {
    return this.http.get<Response>(this.APIURL + '/api/user/getAllCount?access_token='+this.getUserToken());
  }

  getAllUserCountForAdmin() {
    return this.http.get<Response>(this.APIURL + '/api/users/admin-stats?access_token='+this.getUserToken());
  }

  deactivate(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/block-unblock?access_token='+this.getUserToken(),data);
  }

  activate(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/users/block-unblock?access_token='+this.getUserToken(),data);
  }

  deleteUser(userId: string) {
    return this.http.delete<Response>(this.APIURL + '/api/users/'+userId+'?access_token='+this.getUserToken());
  }

  deleteCompany(companyId: string) {
    return this.http.delete<Response>(this.APIURL + '/api/companies/'+companyId+'?access_token='+this.getUserToken());
  }

  uploadProfile(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/profileImages/profile-images/upload', data);
  }
  

  /* Manage Category Pages APIS calling */
  addCategory(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/category/add', data);
  }

  getCategoryById(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/category/getById/' + id);
  }

  updateCategory(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/category/update', data)
  }

  getAllCategories(queryParams: any) {
    return this.http.get<Response>(this.APIURL + `/api/category/getAll?page=${queryParams.page}&limit=${queryParams.limit}&search_query=${queryParams.search}&platform=admin`);
  }
  // deleteCategory(id: string) {
  //   return this.http.get<Response>(this.APIURL + '/api/category/delete/' + id);
  // }
  deactivateCategory(categoryId: string) {
    return this.http.get<Response>(this.APIURL + '/api/category/deactivate/' + categoryId);
  }

  activateCategory(categoryId: string) {
    return this.http.get<Response>(this.APIURL + '/api/category/activate/' + categoryId);
  }

  uploadFile(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/uploadFile', data);
  }

  /* Manage Subscription Pages APIS calling */
  addSubscription(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/subscription/add', data);
  }

  getSubscriptionById(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/subscription/getById/' + id);
  }

  updateSubscription(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/subscription/update', data)
  }

  getAllSubscriptions(queryParams?: any) {
    return this.http.get<Response>(this.APIURL + `/api/subscription/getAll?page=${queryParams.page}&limit=${queryParams.limit}&start=${queryParams.start}&end=${queryParams.end}&search_query=${queryParams.search}`);
  }

  deleteSubscription(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/subscription/delete/' + id);
  }

  /* Manage Rule Pages APIS calling */
  addRule(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/rule/add', data);
  }

  getRuleById(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/rule/getById/' + id);
  }

  updateRule(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/rule/update', data)
  }

  getAllRules() {
    return this.http.get<Response>(this.APIURL + '/api/rule/getAll');
  }

  deleteRule(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/rule/delete/' + id);
  }

  blockRule(ruleId: string) {
    return this.http.get<Response>(this.APIURL + '/api/rule/block/' + ruleId);
  }

  unblockRule(ruleId: string) {
    return this.http.get<Response>(this.APIURL + '/api/rule/unblock/' + ruleId);
  }

  /* Manage Payment Pages APIS calling */
  getAllPayments(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/payment/getAll', data);
  }

  getAllPaymentsForDashboard(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/payment/getAllForDashboard', data);
  }

  /* Manage Debate Pages APIS calling */
  getAllDebates(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/debate/getAll', data);
  }

  hideDebate(debateId: string) {
    return this.http.get<Response>(this.APIURL + '/api/debate/hide/' + debateId);
  }

  unhideDebate(debateId: string) {
    return this.http.get<Response>(this.APIURL + '/api/debate/unhide/' + debateId);
  }

  getDebateById(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/debate/getById/' + id);
  }
  
  /* Manage Report Pages APIS calling */
  getAllReports(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/report/getAll', data);
  }

  getReportById(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/report/getById/' + id);
  }

  updateReport(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/report/updateStatus', data)
  }

  /* Manage Report Pages APIS calling */
  sendNotification(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/customNotification/send', data)
  }

  getAllNotifications(data: any) {
    return this.http.post<Response>(this.APIURL + '/api/customNotification/getAll', data);
  }

  getAllNotificationById(id: string) {
    return this.http.get<Response>(this.APIURL + '/api/customNotification/getById/' + id);
  }

}
