interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Shipping companies',
    url: '/dashboard/allUsers',
    icon: 'icon-people'
  },
  // {
  //   name: 'Manage Categories',
  //   url: '/dashboard/allCategories',
  //   icon: 'icon-puzzle'
  // },
  // {
  //   name: 'Manage Rules',
  //   url: '/dashboard/allRules',
  //   icon: 'icon-list'
  // },
  // {
  //   name: 'Manage Timelines',
  //   url: '/dashboard/allDebates',
  //   icon: 'icon-camrecorder'
  // },
  // {
  //   name: 'Manage Reports',
  //   url: '/dashboard/allReports',
  //   icon: 'icon-drawer'
  // },
  // {
  //   name: 'Manage Subscriptions',
  //   url: '/dashboard/allSubscriptions',
  //   icon: 'icon-badge'
  // },
  // {
  //   name: 'Manage Payments',
  //   url: '/dashboard/allPayments',
  //   icon: 'icon-wallet'
  // },
  // {
  //   name: 'Manage Notifications',
  //   url: '/dashboard/allNotifications',
  //   icon: 'icon-bell'
  // }
];
