import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './tokenInterceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material'
import { MatPaginatorModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MatInputModule } from '@angular/material'
import { MatSortModule } from '@angular/material';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'ng2-truncate';
import { PhonePipe } from './customPipes/pipePhoneFormat';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MatSelectModule, MatIconModule, MatMenuModule } from '@angular/material';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { NgInitDirective } from './directives/ng-init-directive';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,

} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { NumbersOnlyDirective } from './directives/numberOnlyDirective/numbers-only.directive';
import { CharactersOnlyDirectiveDirective } from './directives/charactersOnlyDirective/characters-only-directive.directive';
import { SpinnerComponent } from './views/spinner/spinner.component';
import { ToastrModule } from 'ngx-toastr';

// County code phone number module
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

//test
@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppAsideModule,
        AppBreadcrumbModule.forRoot(),
        AppFooterModule,
        AppHeaderModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        HttpClientModule,
        // DataTableModule,
        // GooglePlaceModule,
        MatTableModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatSortModule,
        AngularFileUploaderModule,
        NgSelectModule,
        TruncateModule,
        NgxSmartModalModule.forRoot(),
        // MatSelectFilterModule,
        MatSelectModule,
        CKEditorModule,
        MatIconModule,
        MatMenuModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: 'toast-top-right',
        }),
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        P404Component,
        P500Component,
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        PhonePipe,
        NgInitDirective,
        NumbersOnlyDirective,
        CharactersOnlyDirectiveDirective,
        SpinnerComponent
    ],
    providers: [{
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        DefaultLayoutComponent,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
