import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserService } from '../../services/user.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

 

 resetToken: string;
 accessToken: string;
 platformType: string;
 resetUrl: string = "/api/user/resetPassword";
 showReset = false;
 showSuccessDiv = false;
 showValidations: boolean = false;
 showMessage: string = '';
 userId: string;
 tokenValidityMessage = "";

 constructor(
  private route: ActivatedRoute,
  private userService: UserService,
  private toastr: ToastrService,
  public router: Router
) { }

  ngOnInit() {
    this.accessToken = this.route.snapshot.queryParamMap.get("access_token") || "";
    if (!this.accessToken) this.router.navigate(['/login']);
   }

  public resetPassword(password: string, confirmPassword: string) {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    if (!re.test(password) || !re.test(password)) {
      this.showValidations = true;
      this.showMessage = "Password should contain 1 lower case, 1 upper case, 1 special character and 1 number.";
      setTimeout(() => {
        this.showValidations = false;
      }, 3000);
    } else if (password !== confirmPassword) {
      this.showValidations = true;
      this.showMessage = "Confirm password should be same as new password.";
      setTimeout(() => {
        this.showValidations = false;
      }, 3000);
    } else {
      var data = {
        newPassword: password,
        confirmPassword: confirmPassword,
        email: this.userService.getVerifiedMail(),
        accessToken : this.accessToken
      }
      this.showValidations = false;
      this.userService.resetPassword(data).subscribe((response) => {
        if (this.platformType === "web") {
          this.toastr.success('Your password has been changed successfully. Please login with your new password');
          this.router.navigate(['']);
        } else {
          this.showSuccessDiv = true;
        }
      }, (error) => {
      });
    }
  }

}