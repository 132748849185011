import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs/operators';
import 'hammerjs';

// text

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet><app-spinner></app-spinner></router-outlet>'
})
export class AppComponent implements OnInit {
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router, 
    private activatedRoute: ActivatedRoute, 
    private title: Title
  ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (isPlatformBrowser(this.platformId)) {
      // Update title for every page
        this.router
          .events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((router) => {
              while (router.firstChild) router = router.firstChild;
                return router;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)).subscribe(
              (event) => {
                this.title.setTitle(event['title']);
            });
    }
  }
}
