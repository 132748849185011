import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { UserService } from '../../services/user.service';
import { Router } from "@angular/router"


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public hideSpinner;
  fileImageUrl: any = "";

  constructor(
    public userService: UserService,
    public router: Router,
    @Inject(DOCUMENT) _document?: any
  ) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }


  ngOnInit() {
    this.hideSpinner = true;
    this.fileImageUrl = this.userService.getProfilePic() ? `${this.userService.APIURL}/api/profileImages/profile-images/download/${this.userService.getProfilePic()}` : "";
  }

  public hideDefaultSpinner(hideSpinner) {
    this.hideSpinner = hideSpinner;
  }

  naviagteToEidProfile() {
    this.router.navigate(['/dashboard/editProfile'])
  }

  public logout() {
    this.userService.deleteToken();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
