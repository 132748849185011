<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form #f="ngForm" (ngSubmit)="f.form.valid && login(email, password)">
                  <div class="text-center">
                    <img src="../../../assets/logo.png" class="img-fluid mb-4" width="100" alt="logo">
                    <!-- <h1>Ship It Pro</h1> -->
                    <!-- <p class="text-muted" style="font-weight: bold; color: #000;">Login to your account</p> -->
                  </div>
                  <div class="form-group mb-3">
                    <label>Email address <span class="text-danger">*</span></label>
                    <input pattern="[^ @]*@[^ @]*" type="email" class="form-control" placeholder="Email address"
                      name="email" [(ngModel)]="email" autocomplete="email" #Email="ngModel"
                      [ngClass]="{ 'is-invalid': f.submitted && Email.invalid }" required>
                    <div *ngIf="f.submitted && Email.invalid" class="invalid-feedback">
                      <div *ngIf="Email.errors.required">Email is required</div>
                      <div *ngIf="Email.errors.pattern">Enter a valid email address</div>
                    </div>
                  </div>
                  <div class="form-group mb-3">
                    <label>Password <span class="text-danger">*</span></label>
                    <input type="password" class="form-control" placeholder="Password" name="password"
                      [(ngModel)]="password" #passwordRef="ngModel" autocomplete="current-password"
                      [ngClass]="{ 'is-invalid': f.submitted && passwordRef.invalid }" required>
                    <div *ngIf="f.submitted && passwordRef.invalid" class="invalid-feedback">
                      <div *ngIf="passwordRef.errors.required">Password is required</div>
                    </div>
                    <div class="error" *ngIf="(f.submitted && !passwordRef.invalid) && (f.submitted && !Email.invalid)">{{message}}</div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button class="btn btn-primary px-4" [disabled]="isSubmit">Login</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0"
                        (click)="router.navigate(['/forgotPassword'])">Forgot password?</button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>