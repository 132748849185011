<style>
  /* previous paging button */
  :host /deep/ a.ng2-smart-page-link.page-link.page-link-prev {
    height: 35px;
  }

  /* next paging button */
  :host /deep/ a.ng2-smart-page-link.page-link.page-link-next {
    height: 35px;
  }

  :host /deep/ ng2-smart-table thead input-filter input {
    text-align: center;
  }
  .profile_img_icon {
    height: 56px;
    width: 58px;
  }
  .profile_name {
    width: 100%;
    max-width: 108px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
  .app-header .navbar-nav .dropdown-menu-right {
    right: 1px !important;
  }

 
</style>


<!-- [navbarBrandFull]="{src: '/assets/img/brand/logo.png', width: 55, height: 50, alt: 'Logo'}" -->
<!-- <app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
[navbarBrandFull]="{src: '../../../assets/logo-icon.png', width: 55, height: 50, alt: 'Logo'}"
  [navbarBrandMinimized]="{src: '/assets/img/brand/logo.png', width: 55, height: 50, alt: 'Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]=false>
  <ul class="nav navbar-nav d-md-down-none">
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" dropdownToggle role="button" aria-haspopup="true" aria-expanded="false">
        <img src="{{userService.APIURL}}/api/profileImages/profile-images/download/{{userService.getProfilePic()}}" class="img-circle profile_img_icon p-1" alt="image" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><span class="font-weight-bold profile_name">{{ userService.getUserName() ? userService.getUserName() : 'Super Admin' }}</span></div>
        <a class="dropdown-item" style="cursor:pointer;" (click)="naviagteToEidProfile()"><i
            class="fa fa-user"></i>Profile</a>
        <a class="dropdown-item" style="cursor:pointer;" (click)="router.navigate(['/dashboard/changePassword'])"><i
            class="fa fa-lock"></i>Change Password</a>
        <a class="dropdown-item" style="cursor:pointer;" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header> -->

<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true" 
    [navbarBrandFull]="{src: '../../../assets/logo-horizontal.png', width: 125, alt: 'Logo'}" 
    [navbarBrandMinimized]="{src: '../../../assets/logo-horizontal.png', width: 125, alt: 'Logo'}"
    [sidebarToggler]="'lg'" [asideMenuToggler]=false>
    <ul class="nav navbar-nav ml-auto">
        <li class="nav-item dropdown" dropdown placement="bottom right">
          <a class="nav-link" dropdownToggle role="button" aria-haspopup="true" aria-expanded="false">
            <img *ngIf="fileImageUrl" src="{{userService.APIURL}}/api/profileImages/profile-images/download/{{userService.getProfilePic()}}" class="img-circle profile_img_icon p-1" alt="image" />
            <img *ngIf="!fileImageUrl" src="../../../../assets/img/avatars/avatar.png" class="img-circle profile_img_icon p-1" alt="image" />
          </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
              <!-- <div class="dropdown-header text-center"><span class="font-weight-bold profile_name">{{ user.name }}</span></div> -->
        <div class="dropdown-header text-center"><span class="font-weight-bold profile_name">{{ userService.getUserName() ? (userService.getUserName() | titlecase) :'Admin' }}</span></div>

        <a class="dropdown-item" style="cursor:pointer;" (click)="naviagteToEidProfile()"><i
          class="fa fa-user"></i>Profile</a>
      <a class="dropdown-item" style="cursor:pointer;" (click)="router.navigate(['/dashboard/changePassword'])"><i
          class="fa fa-lock"></i>Change Password</a>
      <a class="dropdown-item" style="cursor:pointer;" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
           </div>
        </li>
    </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <!-- <app-sidebar-minimizer></app-sidebar-minimizer> -->
  </app-sidebar>
  <!-- Main content -->
  <main class="main mt-4">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <!-- <cui-breadcrumb> -->
      <!-- Breadcrumb Menu-->

    <!-- </cui-breadcrumb> -->
    <!-- deprecation warning for 'app-breadcrumb' -->
    <!--<ol class="breadcrumb">-->
    <!--<app-breadcrumb></app-breadcrumb>-->
    <!--&lt;!&ndash; Breadcrumb Menu&ndash;&gt;-->
    <!--<li class="breadcrumb-menu d-md-down-none">-->
    <!--<div class="btn-group" role="group" aria-label="Button group with nested dropdown">-->
    <!--<a class="btn" href="#"><i class="icon-speech"></i></a>-->
    <!--<a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>-->
    <!--<a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>-->
    <!--</div>-->
    <!--</li>-->
    <!--</ol>-->
    <div class="spinner" [hidden]="hideSpinner">
    </div>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer>
</app-footer>