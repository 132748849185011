import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from '../../services/user.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public email: string = '';
  resetToken: any;
  verifiedEmail: any;

  constructor(
    private userService: UserService,
    public router: Router
  ) { 
  }

  public message = {
    msg: '',
    type: '',
    show: false
  };  
  public model: any = {};

  ngOnInit() {
    let token = this.userService.getUserToken();
    if (token) {
      this.router.navigate(['/dashboard'])
    }
  }

  public showErrorMessage(message: string, type: string) {
    this.message.msg = message;
    this.message.type = type;
    this.message.show = true;
    setTimeout(() => {
      this.message.msg = '';
      this.message.type = '';
      this.message.show = false;
    }, 3000);
  }

  public validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public forgotPassword(email: any) {
    if (!email) {
      this.showErrorMessage("Please enter email address.", "Error");
    }
    else if (!this.validateEmail(email)) {
      this.showErrorMessage("Please enter a valid email address.", "Error");
    }
    else {
      var params = {
        email: email,
        roleType: 'admin'
      }
      this.userService.forgotPassword(params).subscribe((response) => {
        this.email = '';
        console.log('response', response.message.message)
        this.showErrorMessage(`${response.message.message}`, "Success");
        this.userService.setVerifiedMail(response.message.verifiedEmail)
      }, (error) => {
        this.userService.isSpinnerVisible = false;
        const response = error.error;
        if(response.message == 'Email Address does not exist in our system') {
          this.showErrorMessage(response.message, "Error");
        } else {
          // this.showErrorMessage("Sorry, Something went wrong!", "Error");
        }
      })
    }

  }

}
