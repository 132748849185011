<style>
  .app-body {
    height: 100vh;
  }
</style>
<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-4 mx-auto">
          <div class="panel panel-default">
            <div class="card-group">
              <div class="card p-4">
                <div class="card-body">
            <div class="panel-body">
              <div class="text-center">
                <img src="../../../assets/logo-icon.png"  class="img-fluid" width="100" alt="logo">
                <h2 class="text-center">Forgot Password?</h2>
                <p>You can reset your password here.</p>
                <div class="alert" *ngIf="message.show" [ngClass]="{'alert-success': message.type === 'Success', 'alert-danger': message.type === 'Error'}">
                  {{message.msg}}
                </div>
                <div class="panel-body">
                  <form id="register-form" role="form" autocomplete="off" class="form" method="post" (keydown.enter)="$event.preventDefault()">
                    <div class="form-group">
                      <div class="input-group">
                        <span class="input-group-addon"><i class="glyphicon glyphicon-envelope color-blue"></i></span>
                        <input id="email" name="email" placeholder="Email address" class="form-control"
                          [(ngModel)]="email" type="email">
                      </div>
                    </div>
                    <div class="form-group">
                      <input name="recover-submit" class="btn btn-success btn-block" value="Reset Password"
                        type="submit" (click)="forgotPassword(email)">
                    </div>
                    <div class="form-group">
                      <a [routerLink]="[ '/login' ]" class="btn btn-primary text-white btn-block">Go back to login</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  </main>
</div>