import {
  Component,
  Input,
  OnDestroy,
  Inject,
  ViewEncapsulation,
  OnInit
} from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../../services/user.service';



@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
  // selector: 'app-spinner',
  // template: `<div class="preloader" *ngIf="userService.isSpinnerVisible">
  //       <div class="spinner">
  //         <div class="double-bounce1"></div>
  //         <div class="double-bounce2"></div>
  //       </div>
  //   </div>`,
  // encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy, OnInit {
  // public isSpinnerVisible = true;

  @Input()
  public backgroundColor = 'rgba(0, 115, 170, 0.69)';

  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    public userService: UserService,
  ) {
    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.userService.isSpinnerVisible = true;
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.userService.isSpinnerVisible = false;
        }
      },
      () => {
        this.userService.isSpinnerVisible = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.userService.isSpinnerVisible = false;
  }

  ngOnInit() {

  }
}
