import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from "@angular/router"
import { catchError } from "rxjs/operators";
import { UserService } from './services/user.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    public router: Router,
    private toastr: ToastrService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = request.clone({
      setHeaders: {
        Token: `${this.userService.getUserToken()}`
      }
    });

    return next.handle(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        this.userService.deleteToken();
        this.router.navigate(['']);
        //this.toastr.error('Your session has been expired');
        if(error.error.error.message == "login failed"){
        this.userService.errorToaster('Entered email or password is incorrect, please try again with correct credentials.');
        } else {
          this.userService.errorToaster('Your session has been expired');
        }
      } else if (error.status === 400 || error.status === 404) {
        this.toastr.error(error.error.error.message);
      } else if ((error.status == 422)) {
        if(error.error.error.details.codes.email[0] == 'uniqueness'){
          this.userService.errorToaster('Email already exists');
        }else{
          this.userService.errorToaster('Error, Something went wrong');
        }
      }else if (error.status === 500) this.toastr.error('Error, Something went wrong');
      return throwError(error);
    }));
  }
}