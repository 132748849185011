<style>
  .app-body {
    height: 100vh;
  }
</style>
<div class="app-body" [hidden]="showReset">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-4 mx-auto">
          <div class="panel panel-default">
            <div class="card-group">
              <div class="card p-4">
                <div class="card-body">
            <div class="panel-body">
              <div class="text-center">
                <img src="../../../assets/logo-icon.png" class="img-fluid" width="100" alt="logo">
                <div *ngIf="!showSuccessDiv">
                  <h2 class="text-center">Reset Password?</h2>
                  <p>You can reset your password here.</p>
                  <div class="alert alert-danger" *ngIf="showValidations">
                    {{ showMessage }}
                  </div>
                  <div class="panel-body">
                    <form #f="ngForm" (ngSubmit)="f.form.valid && resetPassword(password, confirmPassword)">
                      <div class="form-group">
                        <input type="password" class="form-control" placeholder="New password" name="password" [(ngModel)]="password" 
                        #passwordRef="ngModel" minlength="8" maxlength="15" [ngClass]="{ 'is-invalid': f.submitted && passwordRef.invalid }" required>
                        <div *ngIf="f.submitted && passwordRef.invalid" class="invalid-feedback text-left">
                          <div *ngIf="passwordRef.errors.required">Please enter new password</div>
                          <div *ngIf="passwordRef.errors.minlength">Minimum length is 8</div>
                          <div *ngIf="passwordRef.errors.maxlength">Maximum length is 15</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <input type="password" class="form-control" placeholder="Confirm password" name="confirmPassword" [(ngModel)]="confirmPassword" 
                        #cpasswordRef="ngModel" minlength="8" maxlength="15" [ngClass]="{ 'is-invalid': f.submitted && cpasswordRef.invalid }" required>
                        <div *ngIf="f.submitted && cpasswordRef.invalid" class="invalid-feedback text-left">
                          <div *ngIf="cpasswordRef.errors.required">Please enter confirm password</div>
                          <div *ngIf="cpasswordRef.errors.minlength">Minimum length is 8</div>
                          <div *ngIf="cpasswordRef.errors.maxlength">Maximum length is 15</div>
                        </div>
                      </div>
                      <div class="form-group">
                        <button class="btn btn-primary btn-block">Reset Password</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div *ngIf="showSuccessDiv">
                  <h2 class="text-center">Reset Successful!</h2>
                    <div class="alert alert-success">
                      Your password has been changed successfully. Please login with your new password
                    </div>
                </div>
                <div class="form-group">
                  <a [routerLink]="[ '/login' ]" class="btn btn-primary text-white btn-block">Go back to login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
<div [hidden]="showReset">
  {{ tokenValidityMessage }}
</div>

<!-- <h1>test</h1> -->
