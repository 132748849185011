import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {

  firstName: any;
  lastName: any;
  email: any;
  contactNo: any;
  community:any;
  password:any;
  confirmPassword: any;
  userData: any = {};

  constructor(
    private userService: UserService,
    public router: Router
  ) { }

  ngOnInit() {
    let token = this.userService.getUserToken();
    if (token) {
      this.router.navigate(['/dashboard'])
    }
  }
  public signup(firstName, lastName, email, contactNo, community, password, confirmPassword) {
    if (password == confirmPassword) {

      // return;
      this.userService.signup(this.userData).subscribe((userResponse) => {
        if (userResponse['message'] == "User created") {
          this.router.navigate(['']);
        }
        else {
          console.log("Some error while creating user");
        }
      })
    }
  }

}
