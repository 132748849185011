<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card mx-4">
            <div class="card-body p-4">
              <form
                (ngSubmit)="f.form.valid && signup(firstName,lastName,email,contactNo,community,password,confirmPassword)"
                #f="ngForm">
                <h1>Register</h1>
                <p class="text-muted">Create your account</p>
                <div class="form-group mb-3">
                  <input type="text" class="form-control" placeholder="First Name" [(ngModel)]="userData.firstName"
                    name="firstName" autocomplete="off" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }"
                    #firstName="ngModel" required>
                  <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                    <div *ngIf="firstName.errors.required">First name is required</div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Last Name" [(ngModel)]="userData.lastName"
                    name="lastName" autocomplete="off" [ngClass]="{ 'is-invalid': f.submitted && firstName.invalid }"
                    #firstName="ngModel" required>
                  <div *ngIf="f.submitted && firstName.invalid" class="invalid-feedback">
                    <div *ngIf="firstName.errors.required">First name is required</div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" class="form-control" placeholder="Email Address" [(ngModel)]="userData.email"
                    name="email" autocomplete="off" required>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">@</span>
                  </div>
                  <input type="text" class="form-control" placeholder="Contact Number" [(ngModel)]="userData.contactNo"
                    name="contactNo" autocomplete="off" required>
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Password" [(ngModel)]="userData.password"
                    name="password" autocomplete="new-password" required>
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Repeat password"
                    [(ngModel)]="userData.confirmPassword" name="confirmPassword" autocomplete="new-password" required>
                </div>
                <button class="btn btn-block btn-success">Create Account</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>